
html, body {
    height: 100%;
}

body {
    background-color: #f6fafb; /* Oviva Super Light Green */
    background-image: url(oviva_logo_circular.svg);
    background-repeat: no-repeat;
    background-size: 70vw;
    background-position: 44vw 56vh;
}

#content-wrapper {
    width: 30rem;
    position: absolute;
    /*top: 50%;*/ /* should only be 40% without (downtime) sticky note */
    top: 40%;

    left: 50%;
    transform: translate(-50%, -50%);
    /* transition: transform 0.18s; */
}

#content-wrapper > .row {
    padding: 2rem;
    background-color: white;
}

#downtime-announcement {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #FFE57F !important;
}

#downtime-announcement > p {
    margin-bottom: 0;
}


#content-box .progress {
    margin: -2rem -2rem -2rem -2rem;
    width: 30rem;
}

h1 {
    margin-top: 0;
    margin-bottom: 16px;
}

#logo {
    display: block;
}

h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
}

.subhead {
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
}

input[type=text]:not(.browser-default):focus:not([readonly]) {
    border-bottom-color: #10b797;
    -webkit-box-shadow: 0 1px 0 0 #10b797;
    box-shadow: 0 1px 0 0 #10b797;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label {
    color: #10b797;
}

.btn {
    background-color: #10b797; /* oviva-green-500 */
}

.btn:hover {
    background-color: #0aa689;
}

.btn-flat {
    color: #10b797; /* oviva-green-500 */
}

.btn-flat:hover {
    color: #0aa689;
}

.waves-effect.waves-oviva .waves-ripple {
    background-color: rgba(87, 204, 182, 0.4); /*#57ccb6;*/
}

/* --- login specific --- */
#yubikey-otp-container {
    height: 0;
    margin-top: 0;
    overflow: hidden;
    transition: all 0.18s;
}

.otp-visible #yubikey-otp-container {
    height: 65px;
    margin-top: 15px;
}

#content-wrapper.otp-visible {
    transform: translate(-50%, -50%) translateY(40px);
}

.view-wrapper {
    overflow: hidden;
    padding: 0 !important;
}

.view-container > section {
    padding: 0 !important;
}

.view-container {
    position: relative;
    width: 200%;
    transition: transform 0.38s;
}

.view-container.offset-1 {
    transform: translateX(-50%);
}

.bottom {
    position: absolute;
    bottom: 0;
}

.forgot-button-container {
    width: 50%;
}

#forgot-password-button {
    margin-left: -2rem;
}

/* --- resetPassword specific --- */
#content-box .input-field .hint {
    padding-top: 0;
    margin-top: -16px;
    margin-bottom: 20px;
    font-size: 0.8rem;
    color: #9e9e9e; /* copied from materialize.css */
}

#reset-password-section {
    padding: 0;
}

.color-bars {
    margin-bottom: 0.1rem;
    width: 100%;
    height: 0.3rem;
}

.color-bars > div {
    width: 20%;
    height: 100%;
    float: left;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.color-bars > div > .bar {
    width: 100%;
    height: 100%;
    display: none;
}

#spinner{
    margin: 30px 0px 0px;
}